<template>
  <div>
    <div>
      <div
        class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
      >
        <supplier-selector
          v-if="
            !filterSupplier &&
            $currentUserCan($permissions.PERM_VIEW_ANY_SUPPLIERS)
          "
          :filterOrganization="filterOrganization"
          @supplierChanged="(supplierId) => (selectedSupplier = supplierId)"
        />

        <product-selector
          v-if="
            !filterProduct &&
            $currentUserCan($permissions.PERM_VIEW_ANY_PRODUCTS)
          "
          :filterOrganization="filterOrganization"
          @productChanged="(productId) => (selectedProduct = productId)"
        />

        <div>
          <base-input
            v-model="query"
            type="search"
            prepend-icon="fas fa-search"
            :placeholder="$t('COMMON.SEARCH')"
            clearable
          />
        </div>
      </div>
      <el-table
        class="table-responsive align-items-center table-flush warehouse-product-list"
        header-row-class-name="thead-light"
        :data="filteredWarehouseProducts"
        type="expand"
      >
        <div slot="empty" v-if="loading">
          <img src="/img/loading.gif" style="height: 100px; width: 100px" />
        </div>
        <el-table-column type="expand">
          <template slot-scope="props">
            <form class="" @submit.prevent="">
              <taxes-selector
                :label="$t('COMMON.TAXES')"
                :taxes="warehouseProductsModel[props.row.id].taxes"
                @taxesChanged="
                  (taxes) =>
                    (warehouseProductsModel[props.row.id].taxes = taxes)
                "
              />

              <base-input
                :label="$t('COMMON.EXCERPT')"
                :placeholder="$t('COMMON.EXCERPT')"
                input-classes="form-control-alternative"
              >
                <html-editor
                  v-model="warehouseProductsModel[props.row.id].excerpt"
                >
                </html-editor>
              </base-input>
            </form>
          </template>
        </el-table-column>
        <el-table-column
          v-if="!filterSupplier"
          :label="$t('COMMON.SUPPLIER')"
          prop="supplier.name"
          min-width="80px"
        >
          <template v-slot="{ row }">
            <base-input v-if="row.id < 0">
              <supplier-selector
                :filterOrganization="filterOrganization"
                :supplier="
                  warehouseProductsModel[row.id].supplier
                    ? warehouseProductsModel[row.id].supplier.id
                    : null
                "
                :allowNone="false"
                :showAll="false"
                :disabled="row.id > 0"
                @supplierChanged="
                  (supplierId) => {
                    warehouseProductsModel[row.id].supplier.id = supplierId;
                  }
                "
              />
            </base-input>
            <object-link
              v-if="row.supplier && row.id > 0"
              :object="row.supplier"
            />
          </template>
        </el-table-column>
        <el-table-column
          v-if="!filterProduct"
          :label="$t('COMMON.PRODUCT')"
          prop="product.name"
          min-width="80px"
        >
          <template v-slot="{ row }">
            <base-input v-if="row.id < 0">
              <product-selector
                :filterOrganization="filterOrganization"
                :filterSupplier="warehouseProductsModel[row.id].supplier.id"
                :product="warehouseProductsModel[row.id].product.id"
                :allowNone="false"
                :showAll="false"
                :disabled="row.id > 0"
                @productChanged="
                  (productId, product) => {
                    warehouseProductsModel[row.id].product.id = productId;
                    warehouseProductsModel[row.id].selling_price =
                      product.price;
                    warehouseProductsModel[row.id].taxes = warehouse.taxes;
                    if (product.supplierProduct) {
                      warehouseProductsModel[row.id].buying_price =
                        product.supplierProduct.price;
                      warehouseProductsModel[row.id].sku =
                        product.supplierProduct.sku;
                    } else {
                      warehouseProductsModel[row.id].buying_price =
                        product.price;
                    }
                  }
                "
              />
            </base-input>
            <object-link
              v-if="row.product && row.id > 0"
              :object="row.product"
            />
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('COMMON.QUANTITY')"
          prop="sku"
          min-width="40px"
        >
          <template v-slot="{ row }">
            {{ row.quantity }}
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('PRODUCTS.SKU')"
          prop="sku"
          min-width="50px"
        >
          <template v-slot="{ row }">
            <base-input
              :placeholder="$t('PRODUCTS.SKU')"
              v-model="warehouseProductsModel[row.id].sku"
              input-classes="form-control-alternative"
            >
            </base-input>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('COMMON.SELLING_PRICE')"
          prop="price"
          min-width="40px"
        >
          <template v-slot="{ row }">
            <base-input
              class="my-5"
              :placeholder="$t('COMMON.SELLING_PRICE')"
              v-model="warehouseProductsModel[row.id].selling_price"
              input-classes="form-control-alternative"
            />
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('COMMON.BUYING_PRICE')"
          prop="price"
          min-width="40px"
        >
          <template v-slot="{ row }">
            <base-input
              class="my-5"
              :placeholder="$t('COMMON.BUYING_PRICE')"
              v-model="warehouseProductsModel[row.id].buying_price"
              input-classes="form-control-alternative"
            />
          </template>
        </el-table-column>
        <el-table-column align="center" min-width="20px">
          <div slot-scope="{ row }" class="table-actions">
            <el-tooltip
              content="Delete"
              placement="top"
              v-if="$currentUserCan($permissions.PERM_EDIT_PRODUCTS)"
            >
              <a
                type="text"
                @click="deleteWarehouseProduct(row)"
                class="table-action table-action-delete"
                data-toggle="tooltip"
                style="cursor: pointer"
              >
                <i class="fas fa-trash"></i>
              </a>
            </el-tooltip>
          </div>
        </el-table-column>
      </el-table>

      <div class="row px-3 mt-3">
        <base-button
          type="info"
          class="btn-sm mr-1 col-10"
          style="width: 100%"
          @click="
            () => {
              addLine();
            }
          "
        >
          <i class="fas fa-plus"></i>
          {{ $t("PRODUCTS.ADD_PRODUCT") }}
        </base-button>
        <base-button
          type="primary"
          class="btn-sm ml-1 col"
          style="width: 100%"
          @click="saveWarehouseProducts()"
        >
          <i class="fas fa-save"></i>
          {{ $t("COMMON.SAVE") }}
        </base-button>
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import requestErrorMixin from "@/mixins/request-error-mixin";
import TaxesSelector from "@/components/TaxesSelector.vue";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import SupplierSelector from "../SupplierSelector.vue";
import ProductSelector from "../ProductSelector.vue";

export default {
  name: "product-list-table",

  components: {
    SupplierSelector,
    ProductSelector,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    TaxesSelector,
    HtmlEditor,
  },

  mixins: [requestErrorMixin],

  props: {
    product: {
      type: Object,
      default: null,
      description: "Product",
    },
    supplier: {
      type: Object,
      default: null,
      description: "Supplier",
    },
    warehouse: {
      type: Object,
      default: null,
      description: "Warehouse",
    },
    filterSupplier: {
      type: String,
      default: null,
      description: "Supplier id",
    },
    filterProduct: {
      type: String,
      default: null,
      description: "Product id",
    },
    filterWarehouse: {
      type: String,
      default: null,
      description: "Warehouse id",
    },
    filterOrganization: {
      type: String,
      default: null,
      description: "Organization id",
    },
  },

  data() {
    return {
      query: null,
      selectedRows: [],
      sort: "-created_at",
      filteredWarehouseProducts: [],
      warehouseProducts: [],
      loading: true,
      selectedProduct: null,
      selectedSupplier: null,
      warehouseProductsModel: {},
      deletedWarehouseProducts: {},
    };
  },

  computed: {},

  watch: {
    filterSupplier: {
      handler: "getListDebounced",
      immediate: true,
    },
    filterProduct: {
      handler: "getListDebounced",
      immediate: true,
    },
    filterWarehouse: {
      handler: "getListDebounced",
      immediate: true,
    },
    query: {
      handler: "getListFilteredDebounced",
      immediate: true,
    },
    selectedSupplier: {
      handler: "getListFilteredDebounced",
      immediate: true,
    },
    selectedProduct: {
      handler: "getListFilteredDebounced",
      immediate: true,
    },
    warehouseProducts: {
      handler: "refreshWarehouseProducts",
      immediate: true,
    },
  },

  methods: {
    getListDebounced: _.debounce(function () {
      this.getList();
      this.filterWarehouseProducts();
    }, 300),

    getListFilteredDebounced: _.debounce(function () {
      this.filterWarehouseProducts();
    }, 300),

    async filterWarehouseProducts() {
      this.filteredWarehouseProducts = this.warehouseProducts.filter((item) => {
        if (item.id < 0) {
          return true;
        }
        if (this.selectedSupplier) {
          if (this.selectedSupplier != item.supplier.id) {
            return false;
          }
        }
        if (this.selectedProduct) {
          if (this.selectedProduct != item.product.id) {
            return false;
          }
        }
        if (this.query) {
          if (
            !item.supplier.company_name.includes(this.query) &&
            !item.product.name.includes(this.query) &&
            !item.sku?.includes(this.query) &&
            !item.product.sku?.includes(this.query)
          ) {
            return false;
          }
        }
        return true;
      });
    },

    async getList() {
      this.loading = true;
      try {
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...(this.query ? { search: this.query } : {}),
          },
          page: {
            number: 1,
            size: 999999999,
          },
          include: "supplier,product,warehouse",
        };

        if (this.filterSupplier) {
          params = {
            ...params,
            filter: { ...params.filter, supplier: this.filterSupplier },
          };
        }
        if (this.filterProduct) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              product: this.filterProduct,
            },
          };
        }
        if (this.filterWarehouse) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              warehouse: this.filterWarehouse,
            },
          };
        }

        await this.$store.dispatch("warehouseProducts/list", params);
        this.warehouseProducts = this.$store.getters["warehouseProducts/list"];
        this.filterWarehouseProducts();

        this.total = this.$store.getters["warehouseProducts/listTotal"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async deleteWarehouseProduct(row) {
      if (row.id > 0) {
        const confirmation = await swal.fire({
          title: this.$t("PRODUCTS.DELETE_THIS_WAREHOUSE_PRODUCT"),
          type: "question",
          customClass: {
            popup: "delete-popup",
          },
          buttonsStyling: false,
          showCancelButton: true,
          confirmButtonText: this.$t("COMMON.YES"),
          cancelButtonText: this.$t("COMMON.NO"),
          confirmButtonClass: "btn btn-primary",
          cancelButtonClass: "btn btn-warning",
        });
        if (confirmation.value !== true) {
          return;
        }
      }

      this.deletedWarehouseProducts[row.id] = _.cloneDeep(row);
      this.warehouseProducts = this.warehouseProducts.filter(
        (item) => item.id != row.id
      );
      this.filterWarehouseProducts();
    },

    async saveWarehouseProducts() {
      swal.fire({
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading();
        },
      });

      try {
        for (const line of Object.values(this.warehouseProductsModel)) {
          const warehouseProductData = _.cloneDeep(line);
          if (line.id > 0) {
            await this.$store.dispatch(
              "warehouseProducts/update",
              warehouseProductData
            );
          } else {
            if (
              warehouseProductData.product.id &&
              warehouseProductData.supplier.id &&
              warehouseProductData.selling_price &&
              warehouseProductData.buying_price
            ) {
              delete warehouseProductData.id;
              await this.$store.dispatch(
                "warehouseProducts/add",
                warehouseProductData
              );
            }
          }
        }
        for (const line of Object.values(this.deletedWarehouseProducts)) {
          if (line.id > 0) {
            await this.$store.dispatch("warehouseProducts/destroy", line.id);
          }
        }
        await this.getList();
        this.deletedWarehouseProducts = {};
        this.$notify({
          type: "success",
          message: this.$t("PRODUCTS.PRODUCTS_SAVED"),
        });
        swal.close();
      } catch (error) {
        swal.close();
        await this.showRequestError(error);
      }
    },

    addLine() {
      let newLinesIds = Object.values(this.warehouseProducts)
        .filter((item) => item.id < 0)
        .map((item) => item.id);
      newLinesIds.push(-1);
      const newLineId = Math.min(...newLinesIds) - 1;

      const newWarehouseProduct = {
        type: "warehouse-products",
        id: newLineId,
        sku: this.product ? this.product.sku : null,
        selling_price: this.product ? this.product.price : 0,
        buying_price: this.product ? this.product.price : 0,
        taxes: this.warehouse.taxes,
        relationshipNames: ["supplier", "product", "warehouse"],
        supplier: {
          type: "suppliers",
          id: this.supplier ? this.supplier.id : null,
        },
        product: {
          type: "products",
          id: this.product ? this.product.id : null,
        },
        warehouse: {
          type: "warehouses",
          id: this.warehouse ? this.warehouse.id : null,
        },
      };
      this.warehouseProducts.push(_.cloneDeep(newWarehouseProduct));
      this.warehouseProductsModel[newLineId] = _.cloneDeep(newWarehouseProduct);
      this.filterWarehouseProducts();
    },

    refreshWarehouseProducts() {
      const warehouseProductsModelData = {};
      for (const item of this.warehouseProducts) {
        if (this.warehouseProductsModel[item.id]) {
          warehouseProductsModelData[item.id] =
            this.warehouseProductsModel[item.id];
        } else {
          warehouseProductsModelData[item.id] = _.cloneDeep(item);
        }
      }
      this.warehouseProductsModel = warehouseProductsModelData;
    },
  },
};
</script>

<style>
.warehouse-product-list .el-table__row .form-group {
  margin-bottom: 0;
}
.warehouse-product-list .form-group.taxes-selector .remove-taxes-button {
  display: flex;
  align-items: center;
}
</style>
